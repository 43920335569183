import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Container from '@mui/material/Container';
import line from '../images/line.svg'
import cloud2 from '../images/cloud2.jpg';
import Grid from '@mui/material/Grid';

function Store() {
    const [data, setData] = React.useState([]);
    
    React.useEffect(() => {
    const url = window.location.protocol + "//paradise.ly/api/item/";
      fetch(url)
        .then((response) => response.json())
        .then((json) => setData(json))
        .catch((error) => "");
    }, []);

    const listItems = data.map((item) =>
        <Grid item xs={2} sm={2} md={3} lg={4} xl={4}>
            <Card>
                <CardActionArea>
                    <CardMedia
                    component="img"
                    height="350"
                    image={item.thumbnail}
                    alt={item.title}
                    />
                    <CardContent dir="rtl">
                    <Typography gutterBottom variant="p" className="f3 b" component="div" >
                        {item.title}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                        السعر: {item.price} ريال
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                        الكمية المتبقية: {item.quantity}
                    </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );

    return (  
        <Container maxWidth="xl" sx={{ mt: 5 }} style={{paddingTop:"100px"}}>
            <div style={{textAlign:'center'}}>
                <h1 className="dblue f1 b">المتجر</h1>
                <img alt="line" className="line" src={line}/>
                <h2 className="dblue f2">اشتري أفضل المعدات وابدأ باللعب</h2>
            </div>

            <Grid container dir="rtl" marginTop={1} rowSpacing={10} spacing={10} justifyContent="center" columns={{ xs: 2, sm:3, md: 7, lg: 12, xl: 16 }}>
                {listItems}
            </Grid>
        </Container>
    );
}

export default Store;