import React, {useRef} from "react";
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { AccessTime, PersonalVideo, AddToQueue, InsertInvitation } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import CounterInput from "react-counter-input";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Slider from "react-slick";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import $ from 'jquery';
import { HashLink as Link } from "react-router-hash-link";
import CircularProgress from '@mui/material/CircularProgress';
import swal from 'sweetalert';

// LOCAL-STYLING
const useStyles = makeStyles((theme) => ({

    submitBtn: {
        fontSize: '25px !important',
        background: 'linear-gradient(to right bottom, rgb(64, 9, 241), rgb(18, 30, 97))',
        transition: '0.2s !important',
        '&:hover': {
            background: '#C3A058 !important'
        },
    },

}));

//style for loading modal
const loadingStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius:10,
  };


const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [rtlPlugin]
  });
  

function Reservation() {
    const nameForm = useRef(null);
    //for reservation
    const [value, setValue] = React.useState(new Date());
    //for retreieving pcs
    const [pcData, setData] = React.useState([]);
    const handleChange = (newValue) => {
        setValue(newValue);
    };


    //for loading modal
    const [loadingOpen, setLoadingOpen] = React.useState(false);
    const handleLoadingOpen = () => setLoadingOpen(true);
    const handleLoadingClose = () => setLoadingOpen(false);
    
    //inialize quantity and hours
    var quantity = 1;
    var hours = 1;

    //retreive all pcs
    React.useEffect(() => {
      const url = window.location.protocol + "//paradise.ly/api/pcs/";
      fetch(url)
        .then((response) => response.json())
        .then((json) => setData(json))
        .catch((error) => "");
    }, []);
    
    function validate(form){
        if(form['name'].value === "" || form['email'].value === "" || form['phone'].value === ""){
            return false;
        }
        return true;
    }
    //reservation submit function
    function SubmitReservation() {
        var data=[]
        const form = nameForm.current;
        if(validate(form)){
            handleLoadingOpen();
            var pidValue = $('.slick-current .slide-container').data('pid');

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    name: form['name'].value,
                    email: form['email'].value,
                    phone: form['phone'].value,
                    quantity: quantity,
                    date: value,
                    pid: pidValue,
                    hours: hours,
                })
            };
        
            const url = window.location.protocol + "//paradise.ly/api/reservation/";
                fetch(url, requestOptions)
                    .then((response) => response.json())
                    .then((json) => {
                        data = json;
                        handleLoadingClose();
                        if(data['message'].includes("success")){
                            swal("Success!", data['message'], "success");
                        }else{
                            swal({
                                title: "Couldn't Reserve!",
                                text: data['message'],
                                icon: "warning",
                            });
                        }
                    })
                    .catch((error) => { 
                        handleLoadingClose();
                        swal("Oops!", "Something went wrong!", "error");
                    }
                );
        }
        else{
            swal({
                title: "Missing Fields!",
                text: "Please make sure to fill all fields.",
                icon: "warning",
            });
        }
      }

      //build list of pcs to use in slider
      const listPCS = pcData.map((pc, i) =>
            <div>
                <div className="slide-container"  data-pid={pc.id}>
                    <PersonalVideo sx={{ width: '70px', height: '70px' }} className="slide-icon"></PersonalVideo>
                    <h2 className="slide-title">{pc.title}</h2>
                    <Link smooth to={"/pcs#"+pc.id} className="slide-button">المواصفات</Link>
                    {/* <Button variant="outlined" className="slide-button" >المواصفات</Button> */}
                </div>
            </div>
        );

    const classes = useStyles();
    const theme = useTheme();
    const isMD = useMediaQuery(theme.breakpoints.down("md"));
    const isSM = useMediaQuery(theme.breakpoints.down("sm"));
    const isXS = useMediaQuery(theme.breakpoints.down("xs"));

    const settings = isXS ? { 
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "30px",
        draggable:true,
        focusOnSelect:true,
        slidesToShow: 1,
        speed: 500,
    } : isSM ? { // small
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "30px",
        draggable:true,
        focusOnSelect:true,
        slidesToShow: 1,
        speed: 500,
    } :isMD ? { // medium
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        draggable:true,
        focusOnSelect:true,
        slidesToShow: 3,
        speed: 500,
    } : { // large
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        draggable:true,
        focusOnSelect:true,
        slidesToShow: 3,
        speed: 500,
    };
    return (
        <div>
        {/* modal for loading */}
        <Modal
            open={loadingOpen}
            // onClose={handleLoadingClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={loadingStyle}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
               Reserving your spots...
            </Typography>
                <Box style={{textAlign:'center', marginTop:'15px'}}>
                    <CircularProgress />
                </Box>
            </Box>
        </Modal>

        <Container maxWidth="xl" style={{ paddingTop: "100px" }}>
            <svg height='0px'>
                <defs>
                    <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style={{'stopColor': 'rgb(64, 9, 241)', 'stopOpacity':'1'}} />
                    <stop offset="100%" style={{'stopColor': ' rgb(18, 30, 97)', 'stopOpacity':'1'}} />
                    </linearGradient>
                </defs>
                <rect x="0" y="0" width="0" height="0"/>
            </svg>
            <form ref={nameForm}>
            <Container maxWidth="md" sx={{ mt: 5, textAlign: 'center' }}>
                <Avatar sx={{ backgroundColor: '#C3A058', height: '70px', width: '70px', marginLeft: 'auto', marginRight: 'auto' }}>
                    <h1 style={{ color: 'white' }}>1</h1>
                </Avatar>
                <h2 className="gold">املأ النموذج أدناه</h2>
                <CacheProvider value={cacheRtl}>
                <Paper elevation={8} style={{ marginTop: '40px', borderRadius: 20, textAlign: 'center', padding: 50 }}>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <TextField dir="rtl"
                            required
                            id="standard-required"
                            label="الاسم الكامل"
                            size="large"
                            name={'name'}
                        />
                        <TextField dir="rtl"
                            sx={{ marginTop: '10px' }}
                            required
                            id="standard-required"
                            label="البريد الالكتروني"
                            size="large"
                            name={'email'}
                        />
                        <TextField dir="rtl"
                            sx={{ marginTop: '10px' }}
                            required
                            id="standard-required"
                            label="رقم الهاتف"
                            size="large"
                            name={'phone'}
                        />
                    </FormControl>
                </Paper>
                </CacheProvider>
            </Container>
            <Container maxWidth="md" sx={{ mt: 5 , textAlign: 'center'}}>
                <Avatar sx={{ backgroundColor: '#C3A058', height: '70px', width: '70px', marginLeft: 'auto', marginRight: 'auto' }}>
                    <h1 style={{ color: 'white' }}>2</h1>
                </Avatar>
                <h2 className="gold">اختر التوقيت وعدد الساعات</h2>
                <Paper elevation={8} style={{ marginTop: '40px', borderRadius: 20, textAlign: 'center', padding: 50 }}>
                    <Grid container spacing={0} rowSpacing={10} justifyContent="space-around" columns={{ xs: 1, sm:2, md: 2, lg:2, xl:2 }}>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                            <Avatar sx={{ background: 'linear-gradient(to right bottom, rgb(64, 9, 241), rgb(18, 30, 97));', height: '70px', width: '70px', marginLeft: 'auto', marginRight: 'auto' }}>
                                <AccessTime sx={{ width: '35px', height: '35px' }} />
                            </Avatar>
                            <CounterInput
                                wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto', backgroundColor: 'rgba(151,153,173,.1)', fontSize: '30px', fontWeight: 'bold', paddingLeft: '10px', paddingRight: '20px', borderRadius: '15px', marginTop: '20px', width: '110px' }}
                                btnStyle={{}}
                                min={1}
                                max={20}
                                count={hours}
                                name={'hours'}
                                onCountChange={count => hours = count}
                            />
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                            <Avatar sx={{ background: 'linear-gradient(to right bottom, rgb(64, 9, 241), rgb(18, 30, 97));', height: '70px', width: '70px', marginLeft: 'auto', marginRight: 'auto' }}>
                                <InsertInvitation sx={{ width: '35px', height: '35px' }} />
                            </Avatar>
                            <div style={{ marginTop: '20px' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DateTimePicker
                                        name={'dateTime'}
                                        value={value}
                                        views={['year', 'month', 'day', 'hours']}
                                        inputFormat='dd-mm-yyyy (h a)'
                                        
                                        onChange={handleChange}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
            <Container maxWidth="lg" sx={{ mt: 5, textAlign: 'center'}}>
                <Avatar sx={{ backgroundColor: '#C3A058', height: '70px', width: '70px', marginLeft: 'auto', marginRight: 'auto' }}>
                    <h1 style={{ color: 'white' }}>3</h1>
                </Avatar>
                <h2 className="gold">إختر العرض المناسب وعدد الأجهزة</h2>

                <Paper elevation={8} style={{ marginTop: '40px', borderRadius: 20, textAlign: 'center', padding: 50 }}>
                    <Grid container spacing={0} alignItems="center" justifyContent="center">
                        <Avatar sx={{ background: 'linear-gradient(to right bottom, rgb(64, 9, 241), rgb(18, 30, 97));', height: '70px', width: '70px' }}>
                            <AddToQueue sx={{ width: '35px', height: '35px' }} />
                        </Avatar>
                    </Grid>                    
                    <Slider {...settings} style={{ padding: '45px' }}>  
                        {listPCS}
                    </Slider>
                    <Grid container spacing={0} alignItems="center" justifyContent="center" direction="column">
                        <h3 className="gold">اختر العدد</h3>
                        <CounterInput
                            wrapperStyle={{ backgroundColor: 'rgba(151,153,173,.1)', fontSize: '30px', fontWeight: 'bold', paddingLeft: '10px', paddingRight: '20px', borderRadius: '15px', marginTop: '20px', width: '110px' }}
                            btnStyle={{}}
                            min={1}
                            max={20}
                            count={quantity}
                            name={'quantity'}
                            onCountChange={count => quantity=count}
                        />
                        <h3 className="gold" style={{marginTop: '20px'}}>الحد الاقصى: 20</h3>
                    </Grid>
                </Paper>

                <Container maxWidth="sm" sx={{ mt: 5 }}>
                    <Button variant="contained" fullWidth className={classes.submitBtn} onClick={SubmitReservation}>
                        احجز الآن
                    </Button>
                </Container>
                
            </Container>
            </form>
        </Container>
        </div>
    );
}

export default Reservation;