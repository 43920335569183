import React from "react";
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { makeStyles } from "@material-ui/core/styles";

// LOCAL-STYLING
const useStyles = makeStyles((theme) => ({
    heroContainer:{
        textAlign:'right'
    }
  }));
function Header() {
    const classes = useStyles();
    return (
        
            <Container maxWidth="xl" className={classes.heroContainer} style={{paddingTop:"170px"}}>
                <Typography variant="p" component="p"  className="gray f1 b">
                أهْلًا بِكَ في بارادايس
                </Typography>
                <Typography variant="p" component="p" mt={4} className="gray f2 b">
                أرض الألعاب الملكية
                </Typography>
            </Container>
 
    );
}

export default Header;