import React from "react";
import Container from '@mui/material/Container';
import line from '../images/line.svg'
import square from '../images/Square.png'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, EffectCoverflow, EffectCube, EffectFlip } from "swiper";
import { HashLink as Link } from "react-router-hash-link";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import helmet from '../images/helmet.svg';

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/effect-cube/effect-cube.min.css'

SwiperCore.use([Navigation, Pagination, EffectCoverflow]);

function OurPCs() {
    const [data, setData] = React.useState([]);
    
    React.useEffect(() => {
    const url = window.location.protocol + "//paradise.ly/api/pcs/";
      fetch(url)
        .then((response) => response.json())
        .then((json) => setData(json))
        .catch((error) => "");
    }, []);
    
    const listPCS = data.map((pc, i) =>
        <SwiperSlide key={i}><img src={pc.thumbnail} alt={pc.title} style={{maxWidth: '100%'}}/><Link smooth to={"/pcs#"+pc.id} className="gold swiper-link">مشاهدة المزيد</Link></SwiperSlide>
    );

    const theme = useTheme();
    const isMD = useMediaQuery(theme.breakpoints.down("md"));
    const isSM = useMediaQuery(theme.breakpoints.down("sm"));
    const isXS = useMediaQuery(theme.breakpoints.down("xs"));
    const params = isMD ? {
        navigation: false,
        pagination: true,
        effect: 'coverflow',
        coverflowEffect:{
        //   rotate: 60,
        //   stretch: 0,
        //   depth: 200,
        //   modifier: 1,
          slideShadows: false
        },
        slidesPerView:3,
        centeredSlides: true,
    } : {
        navigation: true,
        pagination: true,
        effect: 'coverflow',
        coverflowEffect:{
        //   rotate: 60,
        //   stretch: 0,
        //   depth: 200,
        //   modifier: 1,
          slideShadows: false
        },
        slidesPerView:3,
        centeredSlides: true,
    }
    var ml = isXS ? '-235px' : isSM ? '-150px' : isMD ? 'auto' : 'auto';
    var w = isXS ? '800px' : isSM ? '1000px' : isMD ? '1000px' : '100%';
    return (  
        <Container id="PCs" maxWidth="xl" sx={{ mt: 10 }}>
            <div style={{ height: '0px', overflow: 'visible'}}>
                {!isSM && <img src={helmet} alt="helmet" style={{position: 'relative', top: '-50px', zIndex: -1}}></img>}
                {isSM && <img src={helmet} alt="helmet" style={{ position: 'relative', top: '-80px', zIndex: -1}}></img>}
            </div>
            <div style={{textAlign:'center', marginBottom: '40px'}}>
                <p className="dblue f1 b">أجهزتنا</p>
                <img alt="line" className="line" src={line}/>
                <p className="dblue f2">العب وتمتع بأقوى أنواع الأجهزة</p>
            </div>
            <div style={{ marginLeft: 'auto', marginRight: 'auto'}}>
                <Swiper {...params} style={{overflow: 'visible', width: w, marginLeft: ml}}>
                    {listPCS}
                </Swiper>
            </div>                
        </Container>
    );
}

export default OurPCs;