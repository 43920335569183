// IMPORTING APIS
import React, { useState } from 'react';
import logo from '../images/Logo.png';
// import logo from '../images/Logo.svg';
import Typography from '@mui/material/Typography';
import { MenuItem } from '@mui/material';
import classNames from 'classnames/bind';
import {
  AppBar,
  Toolbar,
  useMediaQuery,
  Button,
} from "@material-ui/core";
import { bubble as Menu } from 'react-burger-menu';

import Container from '@mui/material/Container';

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { HashLink as Link } from "react-router-hash-link";

// LOCAL-STYLING
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
    background: 'linear-gradient(to bottom, rgba(68, 68, 68, 1), rgba(78, 78, 78, 0.37))',
    boxShadow: 'none'
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'end',
    height: '50px'
  },
  colorWhite: {
    color: 'white',
  },
  bookBtn: {
    borderRadius: 10,
    color: 'white',
    paddingLeft: 40,
    paddingRight: 40,
    marginRight:30
  },

  bookMobileBtn: {
    borderRadius: 10,
    color: 'white',
    paddingLeft: 40,
    paddingRight: 40,
    marginBottom:30,
    marginTop:20
  },
}));

var mobileMenyStyles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    left: '36px',
    top: '32px',
  },
  bmBurgerBars: {
    background: '#C3A058'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '40px',
    width: '40px',
    right: '25px',
    top: '20px'
  },
  bmCross: {
    background: '#bdc3c7',
    width:'4px',
    height:'28px'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top:'0',
    left:'0',
    with:'100%'
  },
  bmMenu: {
    background: '#373a47',
    padding: '0 1.5em 0',
    fontSize: '1.15em',
    width:'100% !important',
    overflow:'hidden'
  },
  bmMorphShape: {
    fill: '#373a47',
    width:'100%'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0 0.8em 0',
    width:'100% !important'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    top:'0',
    left:'0'

  }
}

const Navbar = (props) => {
  const classes = useStyles();
  const [anchor, setAnchor] = React.useState(null);
  const open = Boolean(anchor);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isOpen, setOpen] = useState(false);

  const handleIsOpen = () => {
    setOpen(!isOpen)
  }

  const closeSideBar = () => {
    setOpen(false)
  }

  const handleMenu = (event) => {
    setAnchor(event.currentTarget);
  };

  return (
    <div >
      <AppBar position="fixed" className={classes.root} >
        <Container maxWidth="xl">
          <Toolbar style={{ minHeight: 90 }} >
            {!isMobile &&
              <div style={{ marginRight: "2rem" }}>
                <Button
                  variant="contained"
                  className={classes.bookBtn}
                  style={{ background: 'linear-gradient(to right bottom, #4009F1, #121E61)' }}
                  component={Link}
                  to="/reserve"
                  rel="noopener noreferrer"
                >
                  احجز مقعدا
                </Button>
                <Button
                  variant="text"
                  component={Link}
                  className={props.activePage === 'store' ? 'gold-bold' : classes.colorWhite}
                  to="/store"
                  smooth
                  color="default"
                >
                  المتجر
                </Button>
                <Button
                  variant="text"
                  component={Link}
                  className={classes.colorWhite}
                  to="/#contact"
                  smooth
                  color="default"
                >
                  اتصل بنا
                </Button>
                <Button
                  variant="text"
                  component={Link}
                  className={classes.colorWhite}
                  to="/#about"
                  smooth
                  color="default"
                >
                  من نحن
                </Button>
                <Button
                  variant="text"
                  component={Link}
                  className={props.activePage === 'pcs' ? 'gold-bold' : classes.colorWhite}
                  to="/#PCs"
                  smooth
                  color="default"
                >
                  أجهزتنا
                </Button>
                <Button
                  variant="text"
                  component={Link}
                  to="/#games"
                  className={classes.colorWhite}
                  smooth
                  color="default"
                >
                  ألعابنا
                </Button>
                <Button
                  variant="text"
                  component={Link}
                  className={props.activePage === 'tournaments' ? 'gold-bold' : classes.colorWhite}
                  to="/tournaments"
                  smooth
                  color="default"
                >
                  البطولات
                </Button>
                <Button
                  variant="text"
                  component={Link}
                  className={props.activePage === 'index' ? 'gold-bold' : classes.colorWhite}
                  to="/"
                  smooth
                  color="default"
                >
                  الرئيسية
                </Button>
              </div>
            }
            {isMobile &&
              <Menu left styles={mobileMenyStyles} isOpen={isOpen} onOpen={handleIsOpen} onClose={handleIsOpen}>
                  <Button
                  variant="contained"
                  className={classes.bookMobileBtn}
                  style={{ background: 'linear-gradient(to right bottom, #4009F1, #121E61)' }}
                  component={Link}
                  to="/reserve"
                  rel="noopener noreferrer"
                  onClick={closeSideBar}
                >
                  احجز مقعدا
                </Button>
                <MenuItem
                  component={Link}
                  to="/"
                  className={props.activePage === 'index' ? 'gold-bold' : classes.colorWhite}
                  smooth
                  onClick={closeSideBar}
                >
                  <Typography variant="h6">الرئيسية</Typography>
                </MenuItem>
                <MenuItem
                  component={Link}
                  className={props.activePage === 'store' ? 'gold-bold' : classes.colorWhite}
                  to="/store"
                  smooth
                >
                  <Typography variant="h6">المتجر</Typography>
                </MenuItem>
                <MenuItem
                    onClick={closeSideBar}
                  component={Link}
                  className={props.activePage === 'tournaments' ? 'gold-bold' : classes.colorWhite}
                  to="/tournaments"
                  smooth
                >
                  <Typography variant="h6">البطولات</Typography>
                </MenuItem>
                <MenuItem
                    onClick={closeSideBar}
                  component={Link}
                  to="/#games"
                  smooth
                >
                  <Typography variant="h6">ألعابنا</Typography>
                </MenuItem>
                <MenuItem
                    onClick={closeSideBar}
                  component={Link}
                  to="/#PCs"
                  className={props.activePage === 'pcs' ? 'gold-bold' : classes.colorWhite}
                  smooth
                >
                  <Typography variant="h6">أجهزتنا</Typography>
                </MenuItem>
                <MenuItem
                    onClick={closeSideBar}
                  component={Link}
                  to="/#about"
                  smooth
                >
                  <Typography variant="h6">من نحن</Typography>
                </MenuItem>
                <MenuItem
                    onClick={closeSideBar}
                  component={Link}
                  to="/#contact"
                  smooth
                >
                  <Typography variant="h6">اتصل بنا</Typography>
                </MenuItem>
              </Menu>
            }

            <Link
              to="/"
              className={classes.title}
              onClick={closeSideBar}
            >
              <img src={logo} alt="logo" />
            </Link>

          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default Navbar;
