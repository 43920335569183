import React from "react";
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import line from '../images/line.svg';
import goal from '../images/goal.png'
import story from '../images/story.png';
import sword from '../images/sword.svg';
import { Typography } from "@mui/material";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    boxShadow: theme.shadows[0],
    backgroundColor: "transparent",
    "&:hover":{
        "& .ava": {
            boxShadow: "0px 0px 10px 8px rgb(195 160 88 / 75%)",
            transform: "translateY(-10px)",
            transition: "0.4s ease-in",
          }
    }, 
  }));

export default function About() {
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <Container id="about" maxWidth="xl" sx={{ mt: 15 }}>
            <div dir="rtl" style={{ height: '0px', overflow: 'visible'}}>
                {!isSM && <img src={sword} alt="sword" style={{position: 'relative', top: '-40px', zIndex: -1}}></img>}
                {isSM && <img src={sword} alt="sword" style={{ position: 'relative', top: '-40px', zIndex: -1}}></img>}
            </div>
            <div style={{textAlign:'center'}}>
                <p className="dblue f1 b">من نحن</p>
                <img alt="line" className="line" src={line}/>
                <p className="dblue f2">نحن أكثر من مجرد صالة ألعاب</p>
            </div>
            <Grid marginTop={2.5} container spacing={10} justifyContent="space-around" columns={{ xs: 5, sm:7, md: 15 }}>
                <Grid item xs={5} sm={5} md={6}>
                    <Item><Avatar className="ava" alt="goal" src={goal} sx={{ mx: "auto", width: 150, height: 150, boxShadow: 5, backgroundColor: "#ffffff" }} />
                    <Typography variant="p" component="p" className="dblue f3 b" style={{color:"#121E61", marginTop: '58px'}}>هدفنا</Typography>
                    <Typography variant="p" component="p" className="dblue f4" style={{color:"#000000", marginTop: '20px'}}>At vero eos et accusamus etiusto odio praesentium accusamus etiusto odio data center for managing database.</Typography></Item>
                </Grid>
                <Grid item xs={5} sm={5} md={6}>
                    <Item><Avatar className="ava" alt="Story" src={story} sx={{ mx: "auto", width: 150, height: 150, boxShadow: 5, backgroundColor: "#ffffff" }} />
                    <Typography variant="p" component="p" className="dblue f3 b" style={{color:"#121E61", marginTop: '58px'}}>قصتنا</Typography>
                    <Typography variant="p" component="p" className="dblue f4" style={{color:"#000000", marginTop: '20px'}}>At vero eos et accusamus etiusto odio praesentium accusamus etiusto odio data center for managing database.</Typography></Item>
                </Grid>
            </Grid>
        </Container>
    );
  }