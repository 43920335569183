import React from "react";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import line from '../images/line.svg'
import map from '../images/map.png'
import email from '../images/Email.svg'
import phone from '../images/Phone.svg'
import address from '../images/Address.svg'
import time from '../images/Time.svg'
import Container from '@mui/material/Container';
import stars from '../images/3stars.svg';
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

function Contact() {

    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Container id="contact" maxWidth="xl" sx={{ mt: 15,mb:20 }}>
            <div style={{ height: '0px', overflow: 'visible'}}>
                {!isSM && <img src={stars} alt="3-stars" style={{position: 'relative', top: '-40px', zIndex: -1}}></img>}
                {isSM && <img src={stars} alt="3-stars" style={{ position: 'relative', top: '-80px', zIndex: -1}}></img>}
            </div>
            <div style={{textAlign:'center', marginBottom: '40px'}}>
                <p className="dblue f1 b">اتصل بنا</p>
                <img alt="line" className="line" src={line}/>
                <p className="dblue f2">للاستفسار او المزيد من المعلومات</p>
            </div>
            <Paper elevation={8} style={{ borderRadius:20, textAlign: 'center', padding: 50 }}>
                <a href="https://goo.gl/maps/fATSw8LbkGaU5wWq8" target="_blank"><div style={{ backgroundImage: `url(${map})`}} className="map"/></a>
                <Grid dir="rtl" marginTop={2} container spacing={2} justifyContent="space-around" columns={{ xs: 1, sm:2, md: 4, lg: 4, xl: 4 }}>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                        <img src={email} alt="email"/><p className="gold f4">help@paradise.com</p>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                        <img src={phone} alt="phone"/><p className="gold f4" dir="ltr">+218 XX-XXXXXXX</p>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                        <img src={address} alt="address"/><p className="gold f4">المشاي<br />تاجوراء</p>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                        <img src={time} alt="time"/><p className="gold f4">كل أيام الأسبوع<br /> من 8 صباحاً إلى 12 مساءاً</p>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default Contact;