import React from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

import { HashLink as Link } from "react-router-hash-link";

import footerLogo from '../images/footer_logo.png'
import facebookIcon from '../images/Facebook.svg'
import youtubeIcon from '../images/Youtube.svg'
import instagramIcon from '../images/Instagram.svg'
import twitterIcon from '../images/Twitter.svg'
import { Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
    centerOnXs: {
      [theme.breakpoints.down("md")]: {
        textAlign: "center"
      }
    }
  }));

function Footer() {
    const classes = useStyles();
    return (
        <div className="footer">
            <Container maxWidth="md">
                <Grid container direction="row" spacing={7} >
                    <Grid item xs={6} md={3} className={classes.centerOnXs}>
                        <List dir="rtl">
                            <ListItem component={Link} smooth to="/#PCs">
                            <ListItemIcon style={{color:'#C4C4C4',minWidth: 20}} sx={{ pl: 1 ,pr:0}}>
                                <ArrowLeftIcon />
                            </ListItemIcon>
                            <ListItemText style={{ color: '#E6E6E6',textAlign:'right' }}><Typography variant="p" component="p" className="f4">أجهزتنا</Typography></ListItemText>
                            </ListItem>
                            <ListItem component={Link} smooth to="/#about">
                            <ListItemIcon style={{color:'#C4C4C4',minWidth: 20}} sx={{ pl: 1 ,pr:0}}>
                                <ArrowLeftIcon />
                            </ListItemIcon>
                            <ListItemText style={{ color: '#E6E6E6',textAlign:'right' }}><Typography variant="p" component="p" className="f4">من نحن</Typography></ListItemText>
                            </ListItem>
                            <ListItem component={Link} smooth to="/#contact">
                            <ListItemIcon style={{color:'#C4C4C4',minWidth: 20}} sx={{ pl: 1 ,pr:0}}>
                                <ArrowLeftIcon />
                            </ListItemIcon>
                            <ListItemText style={{ color: '#E6E6E6',textAlign:'right' }}><Typography variant="p" component="p" className="f4">اتصل بنا</Typography></ListItemText>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={6} md={3} className={classes.centerOnXs}>
                        <List dir="rtl">
                            <ListItem component={Link} smooth to="/#home">
                            <ListItemIcon style={{color:'#C4C4C4',minWidth: 20}} sx={{ pl: 1 ,pr:0}}>
                                <ArrowLeftIcon />
                            </ListItemIcon>
                            <ListItemText style={{ color: '#E6E6E6',textAlign:'right' }}><Typography variant="p" component="p" className="f4">الرئيسية</Typography></ListItemText>
                            </ListItem>
                            <ListItem component={Link} smooth to="/#tournaments">
                            <ListItemIcon style={{color:'#C4C4C4',minWidth: 20}} sx={{ pl: 1 ,pr:0}}>
                                <ArrowLeftIcon />
                            </ListItemIcon>
                            <ListItemText style={{ color: '#E6E6E6',textAlign:'right' }}><Typography variant="p" component="p" className="f4">البطولات</Typography></ListItemText>
                            </ListItem>
                            <ListItem component={Link} smooth to="/#games">
                            <ListItemIcon style={{color:'#C4C4C4',minWidth: 20}} sx={{ pl: 1 ,pr:0}}>
                                <ArrowLeftIcon />
                            </ListItemIcon>
                            <ListItemText style={{ color: '#E6E6E6',textAlign:'right' }}><Typography variant="p" component="p" className="f4">ألعابنا</Typography></ListItemText>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.centerOnXs} sx={{ mb: 5 }}>
                        <img src={footerLogo} style={{ width: "330px" }} alt="Paradise Logo"/>
                    </Grid>
                </Grid>
                <div className="footer-devider"></div>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{mt:2}}
                >
                    <Grid item >
                        <a href="" style={{marginRight:"10px"}}>
                            <img src={facebookIcon} style={{ width: "40px" }} alt="facebook"/>
                        </a>
                        <a href="" style={{marginRight:"10px"}}>
                            <img src={youtubeIcon} style={{ width: "40px" }} alt="youtube"/>
                        </a>
                        <a href="" style={{marginRight:"10px"}}>
                            <img src={instagramIcon} style={{ width: "40px" }} alt="instagram"/>
                        </a>
                        <a href="">
                            <img src={twitterIcon} style={{ width: "40px" }} alt="twitter"/>
                        </a>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{mt:1}}
                >
                    <Grid item >
                    <Typography variant="p" component="p" style={{color:"#E6E6E6",fontSize:"14px", marginBottom: '10px'}}>
                     2022 كل الحقوق محفوظة
                    </Typography>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default Footer;