import React, { PureComponent } from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import line from '../images/line.svg';
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default function Statistics() {
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <Container id="about" maxWidth="xl" sx={{ mt: 15 }}>
            {/* <div dir="rtl" style={{ height: '0px', overflow: 'visible'}}>
                {!isSM && <img src={sword} alt="sword" style={{position: 'relative', top: '-40px', zIndex: -1}}></img>}
                {isSM && <img src={sword} alt="sword" style={{ position: 'relative', top: '-40px', zIndex: -1}}></img>}
            </div> */}
            <div style={{textAlign:'center'}}>
                <p className="dblue f1 b">الإحصائيات</p>
                <img alt="line" className="line" src={line}/>
                <p className="dblue f2">اتطلع على بعض من معلومات الصالة</p>
            </div>
            
            <Example/>

        </Container>
    );
  }

class Example extends PureComponent {
    render() {
        const data = [
            {
                name: 'الاثنين',
                Players: 10,
            },
            {
                name: 'الثلاثاء',
                Players: 20,
            },
            {
                name: 'الأربعاء',
                Players: 30,
            },
            {
                name: 'الخميس',
                Players: 40,
            },
            {
                name: 'الجمعة',
                Players: 40,
            },
            {
                name: 'السبت',
                Players: 30,
            },
            {
                name: 'الأحد',
                Players: 100,
            },
          ];
        return(
            <div style={{height: '500px', marginTop: '30px'}}>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                    width={1500}
                    height={500}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip labelStyle={{direction: 'rtl'}}/>
                    <Legend />
                    <Line type="monotone" dataKey="Players" stroke="#8884d8" name="اللاعبين" activeDot={{ r: 8 }} />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        );
    }
}