import React from "react";
import Slider from "react-slick";
import line from '../images/line.svg'
import rectangle from '../images/Rectangle.png'
import Container from '@mui/material/Container';
import { useMediaQuery } from "@material-ui/core";
import controller from '../images/controller.svg';
import { useTheme } from "@material-ui/core/styles";
import {useEffect} from "react";

function Games() {
    const [data, setData] = React.useState([]);
    
    React.useEffect(() => {
      const url = window.location.protocol + "//paradise.ly/api/games/";
      fetch(url)
        .then((response) => response.json())
        .then((json) => setData(json))
        .catch((error) => "");
    }, []);

    const listGames = data.map((game, i) =>
    // <div style={{width: '100%'}}><div style={{ backgroundImage: `url(${game.thumbnail})`}} className="game"/></div>
    <div key={i}>
        <img src={game.thumbnail} className="gameImg" alt={game.name}/>
    </div>
    );

    const theme = useTheme();
    const isMD = useMediaQuery(theme.breakpoints.down("md"));
    const isSM = useMediaQuery(theme.breakpoints.down("sm"));
    const isXS = useMediaQuery(theme.breakpoints.down("xs"));
    const settings = isXS ? { // x small
        className: "center",
        centerMode: true,
        infinite: false,
        centerPadding: "0px",
        slidesToShow: 1,
        speed: 1000,
        rows: 2,
        slidesPerRow: 2,
        dots: true,
    //     responsive: [
    //         {
    //           breakpoint: 1260,
    //           settings: {
    //             rows: 2,
    //             slidesPerRow: 6,
    //           }
    //         },
    //         {
    //             breakpoint: 940,
    //             settings: {
    //               rows: 2,
    //               slidesPerRow: 5,
    //             }
    //           },
    //         {
    //           breakpoint: 600,
    //           settings: {
    //             rows: 2,
    //             slidesPerRow: 4,
    //           }
    //         },
    //         {
    //           breakpoint: 480,
    //           settings: {
    //             rows: 2,
    //             slidesPerRow: 2,
    //           }
    //         }
    //       ]
        
    }
    : isSM ? { // small
        className: "center",
        centerMode: true,
        infinite: false,
        centerPadding: "0px",
        slidesToShow: 1,
        speed: 1000,
        rows: 2,
        slidesPerRow: 4,
        dots: true,
    } :isMD ? { // medium
        className: "center",
        centerMode: true,
        infinite: false,
        centerPadding: "0px",
        slidesToShow: 1,
        speed: 1000,
        rows: 2,
        slidesPerRow: 5,
        dots: true,
    } : { // large
        className: "center",
        centerMode: true,
        infinite: false,
        centerPadding: "0px",
        slidesToShow: 1,
        speed: 1000,
        rows: 2,
        slidesPerRow: 6,
        dots: true,
    };
    return (
        <Container id="games" maxWidth="xl" sx={{ mt: 15 }}>
            <div dir="rtl" style={{ height: '0px', overflow: 'visible'}}>
                {!isSM && <img src={controller} alt="controller" style={{position: 'relative', top: '-60px', zIndex: -1, opacity: '30%'}}></img>}
                {isSM && <img src={controller} alt="controller" style={{ position: 'relative', top: '-90px', zIndex: -1}}></img>}
            </div>
            <div style={{textAlign:'center'}}>
                <p className="dblue f1 b">ألعابنا</p>
                <img alt="line" className="line" src={line}/>
                <p className="dblue f2">اكتشف جميع الألعاب المتواجدة لدينا</p>
            </div>
            <Slider {...settings} style={{ padding: '40px' }}>
                {listGames}
            </Slider>
        </Container>
    );
}

export default Games;