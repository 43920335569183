import React, { Component } from "react";
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import Statistics from "./components/Statistics";
import About from "./components/About";
import Games from "./components/Games";
import OurPCs from "./components/OurPCs";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import NotFound from "./pages/404";
import PCs from "./pages/PCs";
import STORE from "./pages/Store";
import Soon from "./pages/Soon";
import Reservation from './components/Reservation'
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


class App extends Component {
  render(){
    return (
      <Router>

        <Routes>
          <Route path="/" element={
            <div>
                {/* <Header/> */}
                <div id="home" className="header">
                  <Navbar activePage={'index'} />
                  <Header/>
                </div>
                <Games/>
                <OurPCs/>
                {/* <Statistics/> */}
                <About/>
                <Contact/>
            </div>
          }/>
          
          <Route path="pcs" element={
            <div> 
            <Navbar activePage={'pcs'} />
            <PCs />
            </div>
          }/>
          <Route path="reserve" element={
          <div> 
            <Navbar  />
            <Reservation />
            </div>}/>
          <Route path="store" element={
          <div> 
            <Navbar activePage={'store'} />
            <STORE />
            </div>}/>
          <Route path="tournaments" element={
            <div> 
            <Navbar activePage={'tournaments'} />
            <Soon />
            </div>}/>
          <Route path="*" element={<div><Navbar/><NotFound /></div>}/>
        </Routes>
        <Footer/>
      </Router>
    );
  }
}

export default App;