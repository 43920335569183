import React from "react";
import Container from '@mui/material/Container';
import line from '../images/line.svg'

function NotFound() {
    return (  
        <Container maxWidth="xl" sx={{ mt: 25, mb: 40 }}>
            <div style={{textAlign:'center'}}>
                <h1 className="dblue f1 b">خطأ</h1>
                <img alt="line" className="line" src={line}/>
                <h2 className="dblue f2">الصفحة غير متواجدة!</h2>
            </div>
        </Container>
    );
}

export default NotFound;