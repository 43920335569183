import React from "react";
import Container from '@mui/material/Container';
import line from '../images/line.svg'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import pc1 from '../images/PC1.png'
import cpu from '../images/cpu.svg'
import gpu from '../images/gpu.svg'
import ram from '../images/ram.svg'
import keyboard from '../images/keyboard.svg'
import mouse from '../images/mouse.svg'
import headset from '../images/headset.svg'
import { Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import amd from '../images/logos/amd.png';
import coolermaster from '../images/logos/coolermaster.png';
import cougar from '../images/logos/cougar.png';
import gamerstorm from '../images/logos/gamerstorm.png';
import msi from '../images/logos/msi.png';
import nvidia from '../images/logos/nvidia.png';
import teamgroup from '../images/logos/teamgroup.png';
import vertux from '../images/logos/vertux.png';
import viewsonic from '../images/logos/viewsonic.png';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    boxShadow: theme.shadows[0],
    backgroundColor: "transparent",
    "&:hover": {
        "& .img": {
            transform: "scale(1.2)",
            transition: "0.2s ease-in",
        }
    },
}));

function PCs() {

    const [data, setData] = React.useState([]);
    
    React.useEffect(() => {
    const url = window.location.protocol + "//paradise.ly/api/pcs/";
      fetch(url)
        .then((response) => response.json())
        .then((json) => setData(json))
        .catch((error) => "");
    }, []);
    

    const listPCS = data.map(function(pc, i) {
        //alert(pc.pricing_list);
        const listPricings = pc.pricings.map((pricing, i) =>
        <Box key={i} sx={{ float: 'right', marginTop: '15px', marginLeft: '20px', width: '140px', height: '55px', borderRadius: 80, background: 'linear-gradient(to right, #4009F1, #121E61, #121E61)' }}>
           <Box sx={{ float: 'right', width: '40px', height: '40px', borderRadius: 80, background: '#FFFFFF', mt: '8px', marginBottom: '5px', marginRight: '10px', textAlign: "center" }}>
               <table style={{ verticalAlign: 'middle', textAlign: 'center', height: '100%', width: '100%' }}><tbody><tr><td>
                   <Typography variant="p" component="p" style={{ color: "#C3A058", fontSize: "20px", marginBottom: '0px', fontWeight: 'bold' }}>
                       {pricing.hour}س
                   </Typography>
               </td></tr></tbody></table>
           </Box>
           <table style={{ float: 'right', verticalAlign: 'middle', textAlign: 'right', height: '100%', width: '90px', paddingRight: '12px' }}><tbody><tr><td>
               <Typography variant="p" component="p" style={{ color: "#F0F0F0", fontSize: "20px", fontWeight: 500 }}>
                   {pricing.price} دينار
               </Typography>
           </td></tr></tbody></table>
        </Box>
        );
        return <Grid container dir={i % 2 == 0 ? "rtl" : "ltr"} key={i} marginTop={10} spacing={5} justifyContent="center" columns={{ xs: 3, sm: 4, md: 6, lg: 5, xl: 5 }} id={pc.id}>
            <Grid item xs={3} sm={3} md={3} lg={2} xl={2}>
                {/* <div style={{ backgroundImage: `url(${pc1})`}} className="PCs"/> */}
                <img alt={pc.title} src={pc.thumbnail} className="pc"></img>
            </Grid>
            <Grid dir="rtl" item xs={3} sm={3} md={3} lg={3} xl={3}>
                <h1 className="dblue">{pc.title}</h1>
                <h2 className="dblue" style={{ marginTop: '20px' }}>العدد: {pc.description}</h2>
                <h2 className="dblue" style={{ marginTop: '15px' }}>المواصفات:</h2>
                <Grid container dir="rtl" mt={'15px'} rowSpacing={2} spacing={2} justifyContent="center" columns={{ xs: 2, sm: 3, md: 3, lg: 4, xl: 6 }}>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                        <Paper width={130} sx={{ padding: 2, textAlign: 'center' }}>
                            <Box width={60} height={60} marginRight={'auto'} marginLeft={'auto'} marginBottom={'5px'}><img src={cpu} alt="cpu" /></Box>
                            <table style={{ verticalAlign: 'middle', textAlign: 'center', height: 60, width: '100%' }}><tbody><tr><td>
                                <Typography variant="p" component="p" style={{ fontFamily: 'Poppins', color: "#000000", fontSize: "18px", fontWeight: 500 }}>
                                    {pc.cpu}
                                </Typography>
                            </td></tr></tbody></table>
                        </Paper>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                        <Paper width={130} sx={{ padding: 2, textAlign: 'center' }}>
                            <Box width={60} height={60} marginRight={'auto'} marginLeft={'auto'} marginBottom={'5px'}><img src={gpu} alt="gpu" /></Box>
                            <table style={{ verticalAlign: 'middle', textAlign: 'center', height: 60, width: '100%' }}><tbody><tr><td>
                                <Typography variant="p" component="p" style={{ fontFamily: 'Poppins', color: "#000000", fontSize: "18px", fontWeight: 500 }}>
                                    {pc.gpu}
                                </Typography>
                            </td></tr></tbody></table>
                        </Paper>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                        <Paper width={130} sx={{ padding: 2, textAlign: 'center' }}>
                            <Box width={60} height={60} marginRight={'auto'} marginLeft={'auto'} marginBottom={'5px'}><img src={ram} alt="ram" /></Box>
                            <table style={{ verticalAlign: 'middle', textAlign: 'center', height: 60, width: '100%' }}><tbody><tr><td>
                                <Typography dir="ltr" variant="p" component="p" style={{ fontFamily: 'Poppins', color: "#000000", fontSize: "18px", marginBottom: '0px', fontWeight: 500 }}>
                                    {pc.ram} Ram
                                </Typography>
                            </td></tr></tbody></table>
                        </Paper>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                        <Paper width={130} sx={{ padding: 2, textAlign: 'center' }}>
                            <Box width={60} height={60} marginRight={'auto'} marginLeft={'auto'} marginBottom={'5px'}><img src={keyboard} alt="keyboard" /></Box>
                            <table style={{ verticalAlign: 'middle', textAlign: 'center', height: 60, width: '100%' }}><tbody><tr><td>
                                <Typography variant="p" component="p" style={{ fontFamily: 'Poppins', color: "#000000", fontSize: "18px", marginBottom: '0px', fontWeight: 500 }}>
                                    {pc.keyboard}
                                </Typography>
                            </td></tr></tbody></table>
                        </Paper>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                        <Paper width={130} sx={{ padding: 2, textAlign: 'center' }}>
                            <Box width={60} height={60} marginRight={'auto'} marginLeft={'auto'} marginBottom={'5px'}><img src={mouse} alt="mouse" /></Box>
                            <table style={{ verticalAlign: 'middle', textAlign: 'center', height: 60, width: '100%' }}><tbody><tr><td>
                                <Typography variant="p" component="p" style={{ fontFamily: 'Poppins', color: "#000000", fontSize: "18px", marginBottom: '0px', fontWeight: 500 }}>
                                    {pc.mouse}
                                </Typography>
                            </td></tr></tbody></table>
                        </Paper>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                        <Paper width={130} sx={{ padding: 2, textAlign: 'center' }}>
                            <Box width={60} height={60} marginRight={'auto'} marginLeft={'auto'} marginBottom={'5px'}><img src={headset} alt="headset" /></Box>
                            <table style={{ verticalAlign: 'middle', textAlign: 'center', height: 60, width: '100%' }}><tbody><tr><td>
                                <Typography variant="p" component="p" style={{ fontFamily: 'Poppins', color: "#000000", fontSize: "18px", marginBottom: '0px', fontWeight: 500 }}>
                                    {pc.headset}
                                </Typography>
                            </td></tr></tbody></table>
                        </Paper>
                    </Grid>
                </Grid>
                <h2 className="dblue" style={{ marginTop: '25px' }}>السعر:</h2>
                <Box>
                    {listPricings}
                </Box>
            </Grid>
        </Grid>
    });

    return (
        <Container maxWidth="xl" sx={{ mt: 5 }} style={{ paddingTop: "100px" }}>
            <div style={{ textAlign: 'center' }}>
                <h1 className="dblue f1 b">أجهزتنا</h1>
                <img alt="line" src={line} />
                <h2 className="dblue f2">العب وتمتع بأقوى أنواع الأجهزة</h2>
            </div>
            <div>
                {listPCS}
                {/*<Grid container dir="rtl" marginTop={1} spacing={5} justifyContent="center" columns={{ xs: 3, sm: 4, md: 6, lg: 5, xl: 5 }} id="1">*/}
                {/*    <Grid item xs={3} sm={3} md={3} lg={2} xl={2}>*/}
                {/*        {/* <div style={{ backgroundImage: `url(${pc1})`}} className="PCs"/> */}
                {/*        <img alt="pc1" src={pc1} className="pc"></img>*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>*/}
                {/*        <h1 className="dblue">الجهاز العادي</h1>*/}
                {/*        <h2 className="dblue" style={{ marginTop: '20px' }}>العدد: 20</h2>*/}
                {/*        <h2 className="dblue" style={{ marginTop: '15px' }}>المواصفات:</h2>*/}
                {/*        <Grid container dir="rtl" mt={'15px'} rowSpacing={2} spacing={2} justifyContent="center" columns={{ xs: 2, sm: 3, md: 3, lg: 4, xl: 6 }}>*/}
                {/*            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>*/}
                {/*                <Paper width={130} sx={{ padding: 2, textAlign: 'center' }}>*/}
                {/*                    <Box width={60} height={60} marginRight={'auto'} marginLeft={'auto'} marginBottom={'5px'}><img src={cpu} alt="cpu" /></Box>*/}
                {/*                    <table style={{ verticalAlign: 'middle', textAlign: 'center', height: 60, width: '100%' }}><tbody><tr><td>*/}
                {/*                        <Typography variant="p" component="p" style={{ fontFamily: 'Poppins', color: "#000000", fontSize: "18px", fontWeight: 500 }}>*/}
                {/*                            I7 8700*/}
                {/*                        </Typography>*/}
                {/*                    </td></tr></tbody></table>*/}
                {/*                </Paper>*/}
                {/*            </Grid>*/}
                {/*            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>*/}
                {/*                <Paper width={130} sx={{ padding: 2, textAlign: 'center' }}>*/}
                {/*                    <Box width={60} height={60} marginRight={'auto'} marginLeft={'auto'} marginBottom={'5px'}><img src={gpu} alt="gpu" /></Box>*/}
                {/*                    <table style={{ verticalAlign: 'middle', textAlign: 'center', height: 60, width: '100%' }}><tbody><tr><td>*/}
                {/*                        <Typography variant="p" component="p" style={{ fontFamily: 'Poppins', color: "#000000", fontSize: "18px", fontWeight: 500 }}>*/}
                {/*                            GTX 1060*/}
                {/*                        </Typography>*/}
                {/*                    </td></tr></tbody></table>*/}
                {/*                </Paper>*/}
                {/*            </Grid>*/}
                {/*            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>*/}
                {/*                <Paper width={130} sx={{ padding: 2, textAlign: 'center' }}>*/}
                {/*                    <Box width={60} height={60} marginRight={'auto'} marginLeft={'auto'} marginBottom={'5px'}><img src={ram} alt="ram" /></Box>*/}
                {/*                    <table style={{ verticalAlign: 'middle', textAlign: 'center', height: 60, width: '100%' }}><tbody><tr><td>*/}
                {/*                        <Typography dir="ltr" variant="p" component="p" style={{ fontFamily: 'Poppins', color: "#000000", fontSize: "18px", marginBottom: '0px', fontWeight: 500 }}>*/}
                {/*                            8 GB Ram*/}
                {/*                        </Typography>*/}
                {/*                    </td></tr></tbody></table>*/}
                {/*                </Paper>*/}
                {/*            </Grid>*/}
                {/*            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>*/}
                {/*                <Paper width={130} sx={{ padding: 2, textAlign: 'center' }}>*/}
                {/*                    <Box width={60} height={60} marginRight={'auto'} marginLeft={'auto'} marginBottom={'5px'}><img src={keyboard} alt="keyboard" /></Box>*/}
                {/*                    <table style={{ verticalAlign: 'middle', textAlign: 'center', height: 60, width: '100%' }}><tbody><tr><td>*/}
                {/*                        <Typography variant="p" component="p" style={{ fontFamily: 'Poppins', color: "#000000", fontSize: "18px", marginBottom: '0px', fontWeight: 500 }}>*/}
                {/*                            HyperX alloy fps*/}
                {/*                        </Typography>*/}
                {/*                    </td></tr></tbody></table>*/}
                {/*                </Paper>*/}
                {/*            </Grid>*/}
                {/*            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>*/}
                {/*                <Paper width={130} sx={{ padding: 2, textAlign: 'center' }}>*/}
                {/*                    <Box width={60} height={60} marginRight={'auto'} marginLeft={'auto'} marginBottom={'5px'}><img src={mouse} alt="mouse" /></Box>*/}
                {/*                    <table style={{ verticalAlign: 'middle', textAlign: 'center', height: 60, width: '100%' }}><tbody><tr><td>*/}
                {/*                        <Typography variant="p" component="p" style={{ fontFamily: 'Poppins', color: "#000000", fontSize: "18px", marginBottom: '0px', fontWeight: 500 }}>*/}
                {/*                            Razer Deathadder*/}
                {/*                        </Typography>*/}
                {/*                    </td></tr></tbody></table>*/}
                {/*                </Paper>*/}
                {/*            </Grid>*/}
                {/*            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>*/}
                {/*                <Paper width={130} sx={{ padding: 2, textAlign: 'center' }}>*/}
                {/*                    <Box width={60} height={60} marginRight={'auto'} marginLeft={'auto'} marginBottom={'5px'}><img src={headset} alt="headset" /></Box>*/}
                {/*                    <table style={{ verticalAlign: 'middle', textAlign: 'center', height: 60, width: '100%' }}><tbody><tr><td>*/}
                {/*                        <Typography variant="p" component="p" style={{ fontFamily: 'Poppins', color: "#000000", fontSize: "18px", marginBottom: '0px', fontWeight: 500 }}>*/}
                {/*                            HyperX Cloud II*/}
                {/*                        </Typography>*/}
                {/*                    </td></tr></tbody></table>*/}
                {/*                </Paper>*/}
                {/*            </Grid>*/}
                {/*        </Grid>*/}
                {/*        <h2 className="dblue" style={{ marginTop: '25px' }}>السعر:</h2>*/}
                {/*        <Box>*/}
                {/*            <Box sx={{ float: 'right', marginTop: '15px', marginLeft: '20px', width: '140px', height: '55px', borderRadius: 80, background: 'linear-gradient(to right, #4009F1, #121E61, #121E61)' }}>*/}
                {/*                <Box sx={{ float: 'right', width: '40px', height: '40px', borderRadius: 80, background: '#FFFFFF', mt: '8px', marginBottom: '5px', marginRight: '10px', textAlign: "center" }}>*/}
                {/*                    <table style={{ verticalAlign: 'middle', textAlign: 'center', height: '100%', width: '100%' }}><tbody><tr><td>*/}
                {/*                        <Typography variant="p" component="p" style={{ color: "#C3A058", fontSize: "20px", marginBottom: '0px', fontWeight: 'bold' }}>*/}
                {/*                            1س*/}
                {/*                        </Typography>*/}
                {/*                    </td></tr></tbody></table>*/}
                {/*                </Box>*/}
                {/*                <table style={{ float: 'right', verticalAlign: 'middle', textAlign: 'right', height: '100%', width: '90px', paddingRight: '12px' }}><tbody><tr><td>*/}
                {/*                    <Typography variant="p" component="p" style={{ color: "#F0F0F0", fontSize: "20px", fontWeight: 500 }}>*/}
                {/*                        25 دينار*/}
                {/*                    </Typography>*/}
                {/*                </td></tr></tbody></table>*/}
                {/*            </Box>*/}
                {/*            <Box sx={{ float: 'right', marginTop: '15px', marginLeft: '20px', width: '140px', height: '55px', borderRadius: 80, background: 'linear-gradient(to right, #4009F1, #121E61, #121E61)' }}>*/}
                {/*                <Box sx={{ float: 'right', width: '40px', height: '40px', borderRadius: 80, background: '#FFFFFF', mt: '8px', marginBottom: '5px', marginRight: '10px', textAlign: "center" }}>*/}
                {/*                    <table style={{ verticalAlign: 'middle', textAlign: 'center', height: '100%', width: '100%' }}><tbody><tr><td>*/}
                {/*                        <Typography variant="p" component="p" style={{ color: "#C3A058", fontSize: "20px", marginBottom: '0px', fontWeight: 'bold' }}>*/}
                {/*                            1س*/}
                {/*                        </Typography>*/}
                {/*                    </td></tr></tbody></table>*/}
                {/*                </Box>*/}
                {/*                <table style={{ float: 'right', verticalAlign: 'middle', textAlign: 'right', height: '100%', width: '90px', paddingRight: '12px' }}><tbody><tr><td>*/}
                {/*                    <Typography variant="p" component="p" style={{ color: "#F0F0F0", fontSize: "20px", fontWeight: 500 }}>*/}
                {/*                        25 دينار*/}
                {/*                    </Typography>*/}
                {/*                </td></tr></tbody></table>*/}
                {/*            </Box>*/}
                {/*            <Box sx={{ float: 'right', marginTop: '15px', marginLeft: '20px', width: '140px', height: '55px', borderRadius: 80, background: 'linear-gradient(to right, #4009F1, #121E61, #121E61)' }}>*/}
                {/*                <Box sx={{ float: 'right', width: '40px', height: '40px', borderRadius: 80, background: '#FFFFFF', mt: '8px', marginBottom: '5px', marginRight: '10px', textAlign: "center" }}>*/}
                {/*                    <table style={{ verticalAlign: 'middle', textAlign: 'center', height: '100%', width: '100%' }}><tbody><tr><td>*/}
                {/*                        <Typography variant="p" component="p" style={{ color: "#C3A058", fontSize: "20px", marginBottom: '0px', fontWeight: 'bold' }}>*/}
                {/*                            1س*/}
                {/*                        </Typography>*/}
                {/*                    </td></tr></tbody></table>*/}
                {/*                </Box>*/}
                {/*                <table style={{ float: 'right', verticalAlign: 'middle', textAlign: 'right', height: '100%', width: '90px', paddingRight: '12px' }}><tbody><tr><td>*/}
                {/*                    <Typography variant="p" component="p" style={{ color: "#F0F0F0", fontSize: "20px", fontWeight: 500 }}>*/}
                {/*                        25 دينار*/}
                {/*                    </Typography>*/}
                {/*                </td></tr></tbody></table>*/}
                {/*            </Box>*/}
                {/*        </Box>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
                {/*<Grid container marginTop={15} spacing={5} justifyContent="center" columns={{ xs: 3, sm: 4, md: 6, lg: 5, xl: 5 }} id="2">*/}
                {/*    <Grid item xs={3} sm={3} md={3} lg={2} xl={2}>*/}
                {/*        {/* <div style={{ backgroundImage: `url(${pc1})`}} className="PCs"/> */}
                {/*        <img alt="pc1" src={pc1} className="pc"></img>*/}
                {/*    </Grid>*/}
                {/*    <Grid dir="rtl" item xs={3} sm={3} md={3} lg={3} xl={3}>*/}
                {/*        <h1 className="dblue">الجهاز العادي</h1>*/}
                {/*        <h2 className="dblue" style={{ marginTop: '20px' }}>العدد: 20</h2>*/}
                {/*        <h2 className="dblue" style={{ marginTop: '15px' }}>المواصفات:</h2>*/}
                {/*        <Grid container dir="rtl" mt={'15px'} rowSpacing={2} spacing={2} justifyContent="center" columns={{ xs: 2, sm: 3, md: 3, lg: 4, xl: 6 }}>*/}
                {/*            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>*/}
                {/*                <Paper width={130} sx={{ padding: 2, textAlign: 'center' }}>*/}
                {/*                    <Box width={60} height={60} marginRight={'auto'} marginLeft={'auto'} marginBottom={'5px'}><img src={cpu} alt="cpu" /></Box>*/}
                {/*                    <table style={{ verticalAlign: 'middle', textAlign: 'center', height: 60, width: '100%' }}><tbody><tr><td>*/}
                {/*                        <Typography variant="p" component="p" style={{ fontFamily: 'Poppins', color: "#000000", fontSize: "18px", fontWeight: 500 }}>*/}
                {/*                            I7 8700*/}
                {/*                        </Typography>*/}
                {/*                    </td></tr></tbody></table>*/}
                {/*                </Paper>*/}
                {/*            </Grid>*/}
                {/*            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>*/}
                {/*                <Paper width={130} sx={{ padding: 2, textAlign: 'center' }}>*/}
                {/*                    <Box width={60} height={60} marginRight={'auto'} marginLeft={'auto'} marginBottom={'5px'}><img src={gpu} alt="gpu" /></Box>*/}
                {/*                    <table style={{ verticalAlign: 'middle', textAlign: 'center', height: 60, width: '100%' }}><tbody><tr><td>*/}
                {/*                        <Typography variant="p" component="p" style={{ fontFamily: 'Poppins', color: "#000000", fontSize: "18px", fontWeight: 500 }}>*/}
                {/*                            GTX 1060*/}
                {/*                        </Typography>*/}
                {/*                    </td></tr></tbody></table>*/}
                {/*                </Paper>*/}
                {/*            </Grid>*/}
                {/*            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>*/}
                {/*                <Paper width={130} sx={{ padding: 2, textAlign: 'center' }}>*/}
                {/*                    <Box width={60} height={60} marginRight={'auto'} marginLeft={'auto'} marginBottom={'5px'}><img src={ram} alt="ram" /></Box>*/}
                {/*                    <table style={{ verticalAlign: 'middle', textAlign: 'center', height: 60, width: '100%' }}><tbody><tr><td>*/}
                {/*                        <Typography dir="ltr" variant="p" component="p" style={{ fontFamily: 'Poppins', color: "#000000", fontSize: "18px", marginBottom: '0px', fontWeight: 500 }}>*/}
                {/*                            8 GB Ram*/}
                {/*                        </Typography>*/}
                {/*                    </td></tr></tbody></table>*/}
                {/*                </Paper>*/}
                {/*            </Grid>*/}
                {/*            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>*/}
                {/*                <Paper width={130} sx={{ padding: 2, textAlign: 'center' }}>*/}
                {/*                    <Box width={60} height={60} marginRight={'auto'} marginLeft={'auto'} marginBottom={'5px'}><img src={keyboard} alt="keyboard" /></Box>*/}
                {/*                    <table style={{ verticalAlign: 'middle', textAlign: 'center', height: 60, width: '100%' }}><tbody><tr><td>*/}
                {/*                        <Typography variant="p" component="p" style={{ fontFamily: 'Poppins', color: "#000000", fontSize: "18px", marginBottom: '0px', fontWeight: 500 }}>*/}
                {/*                            HyperX alloy fps*/}
                {/*                        </Typography>*/}
                {/*                    </td></tr></tbody></table>*/}
                {/*                </Paper>*/}
                {/*            </Grid>*/}
                {/*            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>*/}
                {/*                <Paper width={130} sx={{ padding: 2, textAlign: 'center' }}>*/}
                {/*                    <Box width={60} height={60} marginRight={'auto'} marginLeft={'auto'} marginBottom={'5px'}><img src={mouse} alt="mouse" /></Box>*/}
                {/*                    <table style={{ verticalAlign: 'middle', textAlign: 'center', height: 60, width: '100%' }}><tbody><tr><td>*/}
                {/*                        <Typography variant="p" component="p" style={{ fontFamily: 'Poppins', color: "#000000", fontSize: "18px", marginBottom: '0px', fontWeight: 500 }}>*/}
                {/*                            Razer Deathadder*/}
                {/*                        </Typography>*/}
                {/*                    </td></tr></tbody></table>*/}
                {/*                </Paper>*/}
                {/*            </Grid>*/}
                {/*            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>*/}
                {/*                <Paper width={130} sx={{ padding: 2, textAlign: 'center' }}>*/}
                {/*                    <Box width={60} height={60} marginRight={'auto'} marginLeft={'auto'} marginBottom={'5px'}><img src={headset} alt="headset" /></Box>*/}
                {/*                    <table style={{ verticalAlign: 'middle', textAlign: 'center', height: 60, width: '100%' }}><tbody><tr><td>*/}
                {/*                        <Typography variant="p" component="p" style={{ fontFamily: 'Poppins', color: "#000000", fontSize: "18px", marginBottom: '0px', fontWeight: 500 }}>*/}
                {/*                            HyperX Cloud II*/}
                {/*                        </Typography>*/}
                {/*                    </td></tr></tbody></table>*/}
                {/*                </Paper>*/}
                {/*            </Grid>*/}
                {/*        </Grid>*/}
                {/*        <h2 className="dblue" style={{ marginTop: '25px' }}>السعر:</h2>*/}
                {/*        <Box>*/}
                {/*            <Box sx={{ float: 'right', marginTop: '15px', marginLeft: '20px', width: '140px', height: '55px', borderRadius: 80, background: 'linear-gradient(to right, #4009F1, #121E61, #121E61)' }}>*/}
                {/*                <Box sx={{ float: 'right', width: '40px', height: '40px', borderRadius: 80, background: '#FFFFFF', mt: '8px', marginBottom: '5px', marginRight: '10px', textAlign: "center" }}>*/}
                {/*                    <table style={{ verticalAlign: 'middle', textAlign: 'center', height: '100%', width: '100%' }}><tbody><tr><td>*/}
                {/*                        <Typography variant="p" component="p" style={{ color: "#C3A058", fontSize: "20px", marginBottom: '0px', fontWeight: 'bold' }}>*/}
                {/*                            1س*/}
                {/*                        </Typography>*/}
                {/*                    </td></tr></tbody></table>*/}
                {/*                </Box>*/}
                {/*                <table style={{ float: 'right', verticalAlign: 'middle', textAlign: 'right', height: '100%', width: '90px', paddingRight: '12px' }}><tbody><tr><td>*/}
                {/*                    <Typography variant="p" component="p" style={{ color: "#F0F0F0", fontSize: "20px", fontWeight: 500 }}>*/}
                {/*                        25 دينار*/}
                {/*                    </Typography>*/}
                {/*                </td></tr></tbody></table>*/}
                {/*            </Box>*/}
                {/*            <Box sx={{ float: 'right', marginTop: '15px', marginLeft: '20px', width: '140px', height: '55px', borderRadius: 80, background: 'linear-gradient(to right, #4009F1, #121E61, #121E61)' }}>*/}
                {/*                <Box sx={{ float: 'right', width: '40px', height: '40px', borderRadius: 80, background: '#FFFFFF', mt: '8px', marginBottom: '5px', marginRight: '10px', textAlign: "center" }}>*/}
                {/*                    <table style={{ verticalAlign: 'middle', textAlign: 'center', height: '100%', width: '100%' }}><tbody><tr><td>*/}
                {/*                        <Typography variant="p" component="p" style={{ color: "#C3A058", fontSize: "20px", marginBottom: '0px', fontWeight: 'bold' }}>*/}
                {/*                            1س*/}
                {/*                        </Typography>*/}
                {/*                    </td></tr></tbody></table>*/}
                {/*                </Box>*/}
                {/*                <table style={{ float: 'right', verticalAlign: 'middle', textAlign: 'right', height: '100%', width: '90px', paddingRight: '12px' }}><tbody><tr><td>*/}
                {/*                    <Typography variant="p" component="p" style={{ color: "#F0F0F0", fontSize: "20px", fontWeight: 500 }}>*/}
                {/*                        25 دينار*/}
                {/*                    </Typography>*/}
                {/*                </td></tr></tbody></table>*/}
                {/*            </Box>*/}
                {/*            <Box sx={{ float: 'right', marginTop: '15px', marginLeft: '20px', width: '140px', height: '55px', borderRadius: 80, background: 'linear-gradient(to right, #4009F1, #121E61, #121E61)' }}>*/}
                {/*                <Box sx={{ float: 'right', width: '40px', height: '40px', borderRadius: 80, background: '#FFFFFF', mt: '8px', marginBottom: '5px', marginRight: '10px', textAlign: "center" }}>*/}
                {/*                    <table style={{ verticalAlign: 'middle', textAlign: 'center', height: '100%', width: '100%' }}><tbody><tr><td>*/}
                {/*                        <Typography variant="p" component="p" style={{ color: "#C3A058", fontSize: "20px", marginBottom: '0px', fontWeight: 'bold' }}>*/}
                {/*                            1س*/}
                {/*                        </Typography>*/}
                {/*                    </td></tr></tbody></table>*/}
                {/*                </Box>*/}
                {/*                <table style={{ float: 'right', verticalAlign: 'middle', textAlign: 'right', height: '100%', width: '90px', paddingRight: '12px' }}><tbody><tr><td>*/}
                {/*                    <Typography variant="p" component="p" style={{ color: "#F0F0F0", fontSize: "20px", fontWeight: 500 }}>*/}
                {/*                        25 دينار*/}
                {/*                    </Typography>*/}
                {/*                </td></tr></tbody></table>*/}
                {/*            </Box>*/}
                {/*        </Box>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
            </div>

            <Grid className="" marginTop={2.5} container spacing={10} alignItems="center" justifyContent="space-around" columns={{ xs: 4, sm: 6, md: 8, lg: 12, xl: 12 }}>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Item><a href="https://www.java.com/" target="_blank"><img width="100%" className="img" src={amd} alt="AMD" /></a></Item>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Item><a href="https://www.java.com/" target="_blank"><img width="100%" className="img" src={nvidia} alt="nvidia" /></a></Item>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Item><a href="https://www.java.com/" target="_blank"><img width="100%" className="img" src={msi} alt="msi" /></a></Item>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Item><a href="https://www.java.com/" target="_blank"><img width="100%" className="img" src={coolermaster} alt="coolermaster" /></a></Item>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Item><a href="https://www.java.com/" target="_blank"><img width="100%" className="img" src={cougar} alt="cougar" /></a></Item>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Item><a href="https://www.java.com/" target="_blank"><img width="100%" className="img" src={gamerstorm} alt="gamerstorm" /></a></Item>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Item><a href="https://www.java.com/" target="_blank"><img width="100%" className="img" src={teamgroup} alt="teamgroup" /></a></Item>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Item><a href="https://www.java.com/" target="_blank"><img width="100%" className="img" src={vertux} alt="vertux" /></a></Item>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Item><a href="https://www.java.com/" target="_blank"><img width="100%" className="img" src={viewsonic} alt="viewsonic" /></a></Item>
                </Grid>
            </Grid>

        </Container>
    );
}

export default PCs;